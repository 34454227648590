import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dropdown,
  Modal,
  Row,
  Col,
  Card,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getListDocument,
  getDetailDocument,
  getPdfFile,
  verifyDocument,
} from "../../../store/documents/DocumentVerification";
import PageTitle from "../../layouts/PageTitle";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import moment from "moment";
import "moment/locale/id";
moment.locale('id');

import {
    successAlert,
    failedAlert
} from "../../../components/notificationAlert/alert";

import PDFViewer from "@ogs-ltd/react-pdf-viewer";
import "@ogs-ltd/react-pdf-viewer/dist/style.css";

const theadData = [
  { heading: "Nomor", sortingVale: "nomor" },
  { heading: "Jenis", sortingVale: "jenis_dokumen" },
  { heading: "Tanggal Dokumen", sortingVale: "tanggal" },
  { heading: "NIP Penandatangan", sortingVale: "nip_ttd" },
  { heading: "Status", sortingVale: "status" },
];

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const DokumenTerproses = () => {
  /* Variabel */
  const [query, setQuery] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [sortType, setSortType] = useState('DESC');
  const activePage = useRef(0);

  const [dataList, setDataList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [detailDocument, setDetailDocument] = useState({ history: [] });
  const [date, setDate] = useState({
    startDate: null,
    endDate: null
  });

  const setDateRange = (event, date) => {
    if(date.startDate) {
      setDate({
        startDate: date.startDate,
        endDate: date.endDate
      });
    } else {
      setDate({
        startDate: null,
        endDate: null
      });
    }
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingDetail, setIsFetchingDetail] = useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);

  const [previewModal, setPreviewModal] = useState(false);
  const [pdfBase64, setPdfBase64] = useState(null);
  const [pdfHeight, setPdfHeight] = useState("100vh");
  const pdfContainerRef = useRef(null);

  /* Dijalankan ketika komponen dirender */
  useEffect(() => {
    fetchListDocument();
  }, [perPage, activePage.current, sortType, date.startDate]);

  useEffect(() => {
    if (pdfContainerRef.current) {
      const containerHeight = pdfContainerRef.current.scrollHeight;
      setPdfHeight(`${containerHeight}px`);
    }
  }, [pdfBase64]);

  /* Local Function */
  const handleSortTypeChange = (value) => {
    setSortType(value);
  };

  const handlePerPageChange = (value) => {
    setPerPage(value);
    activePage.current = 0;
  };

  const onPageClick = (i) => {
    activePage.current = i;
    fetchListDocument();
  };

  const pagination = Array.from(
    { length: Math.ceil(dataCount / perPage) },
    (_, i) => i + 1
  );

  const formatDate = (date) => {
    return moment(date).format("LL");
  };

  const formatDateFull = (date) => {
    return moment(date).format("lll");
  };

  const openModalPratinjau = (data) => {
    setPreviewModal(true);
    setPdfBase64(null)
    fetchDetailDocument(data.id);
    
    if(data.status === '3') {
      fetchVerifiedDocument(data.id);
    } else {
      fetchPdfFile(data.id);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchListDocument();
    }
  };

  /* API Function */
  const fetchListDocument = () => {
    if (isFetching) return;

    setIsFetching(true);
    
    var queryType = query || date.startDate ? 'query' : 'list'
    var params = {
      offset: activePage.current * perPage,
      jumlah: perPage,
      sort: sortType.toLowerCase(),
      status: "3,4,5",
    };

    if(query) {
      params['nomor'] = query
    }

    if(date.startDate) {
      params['date_start'] = moment(date.startDate).format('DD-MM-YYYY'),
      params['date_end'] = moment(date.endDate).format('DD-MM-YYYY')
    }

    getListDocument(params, queryType)
      .then((response) => {
        var status = response.data.status;

        if (status) {
          setDataList(response.data.data.documents || []);
          setDataCount(response.data.data.total_count || 0);

          if(dataList.length === 0 && dataCount > 0) {
            activePage.current = 1;
          }
        } else {
          failedAlert("Gagal Memuat Daftar Dokumen", response.data.message);
        }
      })
      .catch((error) => {
        failedAlert("Gagal Memuat Daftar Dokumen", error.response.data.message);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const fetchDetailDocument = (id) => {
    if (isFetchingDetail) return;

    setIsFetchingDetail(true);

    const params = {
      id: id,
    };

    getDetailDocument(params)
      .then((response) => {
        var status = response.data.status;

        if (status) {
          response.data.data['history'] = response.data.data['history'].reverse()
          setDetailDocument(response.data.data);
        } else {
          failedAlert("Gagal Memuat Detail Dokumen", response.data.message);
        }
      })
      .catch((error) => {
        failedAlert("Gagal Memuat Detail Dokumen", error.response.data.message);
      })
      .finally(() => {
        setIsFetchingDetail(false);
      });
  };

  const fetchPdfFile = (id) => {
    if (isFetchingPdf) return;

    setIsFetchingPdf(true);

    const params = {
      id: id,
    };

    getPdfFile(params)
      .then((response) => {
        var status = response.data.status;

        if (status) {
          setPdfBase64(`data:application/pdf;base64,${response.data.data.file}`);
        } else {
          failedAlert("Gagal Memuat Dokumen", response.data.message);
        }
      })
      .catch((error) => {
        failedAlert("Gagal Memuat Dokumen", error.response.data.message);
      })
      .finally(() => {
        setIsFetchingPdf(false);
      });
  };
  
  const fetchVerifiedDocument = (id) => {
    if (isFetchingPdf) return;

    setIsFetchingPdf(true);

    const params = {
      id: id,
    };

    verifyDocument(params)
      .then((response) => {
        var status = response.data.status;

        if (status) {
          setPdfBase64(`data:application/pdf;base64,${response.data.data.file}`);
        } else {
          failedAlert("Gagal Memuat Dokumen", response.data.message);
        }
      })
      .catch((error) => {
        failedAlert("Gagal Memuat Dokumen", error.response.data.message);
      })
      .finally(() => {
        setIsFetchingPdf(false);
      });
  };

  return (
    <>
      <PageTitle activeMenu={"Dokumen Terproses"} motherMenu={"Beranda"} />
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="table-responsive">
                <div id="dataList" className="dataTables_wrapper no-footer">
                  <Row>
                    <Col lg={9} sm={6}>
                      <Row className="g-0">
                        <Col lg="auto">
                          <Dropdown className="search-drop ms-0 me-2">
                            <Dropdown.Toggle className="search-drop-btn">
                              {perPage}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => handlePerPageChange(5)}
                              >
                                5
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handlePerPageChange(10)}
                              >
                                10
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handlePerPageChange(25)}
                              >
                                25
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handlePerPageChange(50)}
                              >
                                50
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col lg="auto">
                          <Dropdown className="search-drop ms-0">
                            <Dropdown.Toggle className="search-drop-btn">
                              {sortType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => handleSortTypeChange("ASC")}
                              >
                                ASC
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleSortTypeChange("DESC")}
                              >
                                DESC
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} sm={6}>
                      <Row className="g-0">
                        <Col>
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Cari Nomor Surat..."
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                        </Col>
                      </Row>
                      <Row className="g-0">
                        <Col>
                          <DateRangePicker
                            onApply={setDateRange}
                            className="text-right"
                            initialSettings={{
                              locale: {
                                format: "DD/MM/YYYY",
                                applyLabel: "Terapkan",
                                cancelLabel: "Batal",
                                fromLabel: "Dari",
                                toLabel: "Ke",
                                customRangeLabel: "Kustom",
                                weekLabel: "M",
                                daysOfWeek: moment.weekdaysMin(),
                                monthNames: moment.months(),
                              },
                            }}
                          >
                            <input type="text" className="form-control" />
                          </DateRangePicker>
                        </Col>
                        {date.startDate !== null && (
                          <Col lg="auto">
                            <Button
                              variant="danger"
                              onClick={() =>
                                setDateRange(null, {
                                  startDate: null,
                                  endDate: null,
                                })
                              }
                            >
                              Reset
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {isFetching ? (
                    <div className="mt-2 text-center d-flex align-items-center justify-content-center">
                      <Spinner
                        animation="border"
                        role="status"
                        className="me-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                      <span>Sedang memuat data...</span>
                    </div>
                  ) : dataList.length > 0 ? (
                    <table
                      id="document-list"
                      className="display dataTable no-footer w-100"
                    >
                      <thead>
                        <tr>
                          {theadData.map((item, ind) => (
                            <th key={ind}>{item.heading}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.map((data, index) => (
                          <tr key={index}>
                            <td>{data.nomor}</td>
                            <td>{data.jenis_dokumen}</td>
                            <td>{formatDate(data.tanggal)}</td>
                            <td>{data.nip_ttd}</td>
                            <td>
                              {data.status === "1" ? (
                                <span className={`badge badge-light`}>
                                  Diterima
                                </span>
                              ) : data.status === "2" ? (
                                <span className={`badge badge-info`}>
                                  Verifikasi
                                </span>
                              ) : data.status === "3" && data.persetujuan === "TTE" ? (
                                <span className={`badge badge-success`}>
                                  Disetujui-TTE
                                </span>
                              ) : data.status === "3" && data.persetujuan === "mandiri" ? (
                                <span className={`badge badge-primary`}>
                                  Disetujui-Mandiri
                                </span>
                              ) : data.status === "4" ? (
                                <span className={`badge badge-warning`}>
                                  Revisi
                                </span>
                              ) : (
                                <span className={`badge badge-danger`}>
                                  Tidak Disetujui
                                </span>
                              )}
                            </td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="primary"
                                  className="light sharp i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => openModalPratinjau(data)}
                                  >
                                    Pratinjau
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center py-3">
                      Tidak ada dokumen yang perlu diaksi
                    </div>
                  )}
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      Menampilkan{" "}
                      {dataList.length === 0
                        ? 0
                        : activePage.current * perPage + 1}{" "}
                      -{" "}
                      {dataCount > (activePage.current + 1) * perPage
                        ? (activePage.current + 1) * perPage
                        : dataCount}{" "}
                      dari {dataCount} data
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={() =>
                          activePage.current > 0 &&
                          onPageClick(activePage.current - 1)
                        }
                      >
                        {"<<"}
                      </Link>
                      <span>
                        {pagination.map((number, i) => (
                          <Link
                            key={i}
                            to="#"
                            className={`paginate_button  ${
                              activePage.current === i ? "current" : ""
                            }`}
                            onClick={() => onPageClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={() =>
                          activePage.current + 1 < pagination.length &&
                          onPageClick(activePage.current + 1)
                        }
                      >
                        {">>"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal */}
      <Modal className="fade bd-example-modal-xl" show={previewModal} size="xl">
        <Modal.Header>
          <Modal.Title>Detail Dokumen</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setPreviewModal(false)}
          />
        </Modal.Header>
        {isFetchingPdf ? (
          <Modal.Body className="d-flex justify-content-center align-items-center">
            <div className="mt-2 text-center d-flex align-items-center justify-content-center">
              <Spinner animation="border" role="status" className="me-2">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <span>Sedang memuat dokumen...</span>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Row>
              <Col>
                <Card className="bg-light">
                  <div className="card-header border-0 pb-0">
                    <h4 className="card-title">Pratinjau</h4>
                  </div>
                  {/* <iframe
                    className="px-3 pt-3"
                    src={pdfBase64}
                    title="Pratinjau Dokumen"
                    width="100%"
                    height="500vh"
                    style={{ border: "none" }}
                  /> */}
                  <div
                    className="px-3 pt-2"
                    ref={pdfContainerRef}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: pdfHeight,
                      border: "transparent",
                      width: "100%",
                      overflow: "auto"
                    }}
                  >
                    <PDFViewer src={pdfBase64} />
                  </div>

                  {pdfBase64 && (
                    <div className="text-center mb-3">
                      <a
                        href={pdfBase64}
                        download={`${detailDocument["nomor"]}_${detailDocument["jenis_dokumen"]}${detailDocument["status"] === "3" ? '_signed' : ''}.pdf`}
                      >
                        <Button
                          variant="success"
                          className="mt-3"
                          size="sm"
                          onClick={() =>
                            successAlert("Dokumen berhasil diunduh")
                          }
                        >
                          Unduh
                        </Button>
                      </a>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
            <Row className="pb-0">
              <Col
                xl={
                  detailDocument["status"] === "4" ||
                  detailDocument["status"] === "5"
                    ? 6
                    : 12
                }
                lg={12}
              >
                <Card className="bg-light">
                  <div className="card-header border-0 pb-0">
                    <h4 className="card-title">History</h4>
                  </div>
                  <div className="card-body p-0">
                    <div
                      id="DZ_W_TimeLine"
                      className="widget-timeline dz-scroll my-4 px-3"
                    >
                      {detailDocument["history"]
                        .reverse()
                        .map((data, index) => (
                          <ul className="timeline" key={index}>
                            <li>
                              <div className="timeline-badge warning"></div>
                              <Link
                                className="timeline-panel text-muted"
                                disabled
                              >
                                <small>{formatDateFull(data.updated_at)}</small>
                                <h6 className="mb-0">
                                  {data.status == "revision"
                                    ? "Dokumen direvisi"
                                    : data.status == "rejected"
                                    ? "Dokumen tidak disetujui"
                                    : data.message}
                                </h6>
                              </Link>
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div>
                </Card>
              </Col>
              {(detailDocument["status"] === "4" ||
                detailDocument["status"] === "5") && (
                <Col xl="6" sm="12">
                  <Card className="bg-light">
                    <div className="card-header border-0 pb-0">
                      <h4 className="card-title">Catatan/Keterangan</h4>
                    </div>
                    <Card.Body>
                      <h5 className="fw-normal">
                        {detailDocument["message"] ||
                          "( Tidak ada keterangan )"}
                      </h5>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default DokumenTerproses;
