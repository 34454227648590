import { lazy, Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import 'rsuite/dist/rsuite-no-reset.min.css';
import "./assets/css/style.css";

import Index from './jsx/router/index';

const SignUp = lazy(() => import('./jsx/pages/authentication/Registration'));
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/authentication/Login')), 500);
    });
});
const Verifikasi = lazy(() => import('./jsx/pages/verification/Verification')); // Import halaman publik

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

function App(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!window.location.pathname.includes('/verifikasi/')) {
            checkAutoLogin(dispatch, navigate);
        }
    }, [dispatch, navigate]);

    return (
        <div className="vh-100">
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
            }>
                <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route path='/page-register' element={<SignUp />} />
                    <Route path='/verifikasi/:id' element={<VerifikasiWrapper />} /> {/* Rute publik */}
                    {props.isAuthenticated && <Route path='/*' element={<Index />} />}
                </Routes>
            </Suspense>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

const VerifikasiWrapper = () => {
    const { id } = useParams();
    return <Verifikasi id={id} />;  
  };
  

export default withRouter(connect(mapStateToProps)(App));
