import Swal from 'sweetalert2';

export function firstCapitalized(text) {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1) || text
};

export function capitalizeEachWord(words) {
  var separateWord = words?.toLowerCase().split(' ');
  for (var i = 0; i < separateWord?.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
    separateWord[i].substring(1);
  }

  return separateWord?.join(' ')
}

export function makeArrayUnique(array) {
  const uniqueMap = new Map();
  return array.reduce((uniqueArray, obj) => {
    const jsonString = JSON.stringify(obj);
    if (!uniqueMap.has(jsonString)) {
      uniqueMap.set(jsonString, true);
      uniqueArray.push(obj);
    }
    return uniqueArray;
  }, []);
}

export function informationAlert(text) {
  Swal.fire({
    icon: 'info',
    title: 'Info!',
    html: `<small className="mt-1 mb-2">${text}</small>`,
    timer: 4000,
    showConfirmButton: false,
  });
};

export function warningAlert(text) {
  Swal.fire({
    icon: 'warning',
    title: 'Maaf!',
    html: `<small className="mt-1 mb-2">${text}</small>`,
    timer: 4000,
    showConfirmButton: false,
  });
};

export function successAlert(text) {
  Swal.fire({
    icon: 'success',
    title: 'Berhasil',
    html: `<small className="mt-1 mb-2 text-dark">${text}</small>`,
    timer: 4000,
    showConfirmButton: false,
  });
};

export function failedAlert(text, error) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-danger',
    },
    buttonsStyling: false
  });

  swalWithBootstrapButtons.fire({
    icon: 'error',
    title: text,
    html: `<small className="mt-1 mb-0">${error}</small>`,
    showConfirmButton: true,
    confirmButtonText: 'Tutup',
  });
};
