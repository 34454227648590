import React, { useReducer, useContext, useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);
  const [state, setState] = useReducer(reducer, initialState);
  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  // Ambil roles dari localStorage
  const roles = JSON.parse(localStorage.getItem("userRoles")) || [];

  // Filter menu berdasarkan roles yang dimiliki pengguna
  const filteredMenuList = MenuList.filter((menu) =>
    menu.roles.some((role) => roles.includes(role))
  );

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    MenuList.forEach((data) => {
      data.content?.forEach((item) => {
        if (path === item.to) {
          setState({ active: data.title });
        }
        item.content?.forEach((ele) => {
          if (path === ele.to) {
            setState({ activeSubmenu: item.title, active: data.title });
          }
        });
      });
    });
  }, [path]);

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {filteredMenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li
                  className={`nav-label ${menuClass} ${data.extraclass}`}
                  key={index}
                >
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? "mm-active" : ""
                  }${data.to === path ? "mm-active" : ""}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                        <span className="badge badge-xs style-1 badge-danger">
                          {data.update}
                        </span>
                      </Link>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${
                            menuClass === "mm-collapse" ? "mm-show" : ""
                          }`}
                        >
                          {data.content.map((subMenu, subIndex) => {
                            return (
                              <li
                                key={subIndex}
                                className={`${
                                  state.activeSubmenu === subMenu.title
                                    ? "mm-active"
                                    : ""
                                }${subMenu.to === path ? "mm-active" : ""}`}
                              >
                                {subMenu.content &&
                                subMenu.content.length > 0 ? (
                                  <>
                                    <Link
                                      to={subMenu.to}
                                      className={
                                        subMenu.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(subMenu.title);
                                      }}
                                    >
                                      {subMenu.title}
                                    </Link>
                                    <Collapse
                                      in={
                                        state.activeSubmenu === subMenu.title
                                          ? true
                                          : false
                                      }
                                    >
                                      <ul
                                        className={`${
                                          menuClass === "mm-collapse"
                                            ? "mm-show"
                                            : ""
                                        }`}
                                      >
                                        {subMenu.content.map(
                                          (subSubMenu, subSubIndex) => {
                                            return (
                                              <li key={subSubIndex}>
                                                <Link
                                                  className={`${
                                                    path === subSubMenu.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={subSubMenu.to}
                                                >
                                                  {subSubMenu.title}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  <Link
                                    to={subMenu.to}
                                    className={`${
                                      subMenu.to === path ? "mm-active" : ""
                                    }`}
                                  >
                                    {subMenu.title}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link
                      to={data.to}
                      className={`${data.to === path ? "mm-active" : ""}`}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
