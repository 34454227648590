 import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          © Copyright {d.getFullYear()} {" "}
          <a href="https://karantinaindonesia.go.id" target="_blank">
            Badan Karantina Indonesia
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
