import {
    formatError,
    login,
    refresh,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';

import { 
    successAlert,
    failedAlert
} from '../../jsx/components/alert/alert';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';  
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';

export function signupAction(email, password, navigate) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                //history,
            );
            dispatch(confirmedSignupAction(response.data));
            navigate('/beranda');
			//history.push('/beranda');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function Logout(navigate) {
	localStorage.removeItem('userDetails');
	localStorage.removeItem('access_token');
	localStorage.removeItem('userRoles');
    navigate('/login');
    
	return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(username, password, navigate) {
    return (dispatch) => {
         login(username, password)
            .then((response) => {
                var status = response.data.status
                if(status) {
                    successAlert('Login berhasil')
                    saveTokenInLocalStorage(response.data.data);
                    runLogoutTimer(
                        dispatch,
                        3600 * 1000, // Umur token = 3600 detik = 1 jam
                        navigate,
                    );
                    dispatch(loginConfirmedAction(response.data.data));			              
                    navigate('/beranda');
                } else {
                    failedAlert('Login gagal', response.data.message)
                }
            })
            .catch((error) => {		
                failedAlert('Login gagal', error.response.data.message)
            });
    };
}

export function refreshToken() {
    return (dispatch) => {
        refresh()
            .then((response) => {
                var status = response.data.status
                if(status) {
                    var userDetails = JSON.parse(localStorage.getItem('userDetails'))

                    userDetails['access_token'] = response.data.data.access_token
                    userDetails['expired_time'] = response.data.data.expired_time

                    saveTokenInLocalStorage(userDetails);
                    runLogoutTimer(
                        dispatch,
                        3600 * 1000, // Umur token = 3600 detik = 1 jam
                        navigate,
                    );
                } else {
                    failedAlert('Gagal Memperbarui Token', response.data.message);
                }
            })
            .catch((error) => {
                failedAlert('Gagal Memperbarui Token', error.response.data.message);
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export const navtoggle = () => {    
    return {        
      type: 'NAVTOGGLE',
    };
};