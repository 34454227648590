import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Logout from "../nav/Logout";

const Avatar = ({
  initials,
  size = 50,
  outlineColor = "#F3BD58",
  outlineWidth = 2,
  href = "#",
}) => {
  const style = {
    width: size,
    height: size,
    borderRadius: "50%",
    backgroundColor: "#ddd",
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: size / 2.5,
    fontWeight: "bold",
    border: `${outlineWidth}px solid ${outlineColor}`,
    boxSizing: "border-box",
    textDecoration: "none",
    cursor: "pointer",
    transition: "transform 0.3s",
  };

  return (
    <a
      href={href}
      style={style}
    >
      {initials}
    </a>
  );
};

const Header = ({ onNote }) => {
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
  }, []);

  const getInisial = (name) => {
    var namaToInisial = name.split(' ')

    if(namaToInisial.length == 1) {
      return namaToInisial[0].substring(0, 2)
    }
    else {
      return namaToInisial[0].substring(0, 1) + namaToInisial[1].substring(0, 1)
    }
  };

  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left" />
              <ul className="navbar-nav header-right ml-auto"> {/* ml-auto untuk membuat elemen menempel ke kanan */}
                <Dropdown as="li" className="nav-item header-profile ml-auto">
                  <div className="align-item-middle text-end">
                    <h6 className="mb-0">{userDetails["nama"] || ""}</h6>
                    <small>{userDetails.nip}</small>
                  </div>
                  <Dropdown.Toggle
                    to={"#"}
                    className="nav-link i-false"
                    as="div"
                  >
                    <Avatar
                      initials={userDetails["nama"] ? getInisial(userDetails["nama"]) : 'NN'}
                      size={40}
                      outlineColor="#123138"
                      outlineWidth={1}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="end"
                    className="mt-3 dropdown-menu dropdown-menu-right "
                  >
                    <Logout />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;


