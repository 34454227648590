import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from "../../../assets/images/logo.png";
import { Spinner } from "react-bootstrap";

import { getCounterDocument } from "../../../store/documents/DocumentVerification";

import { failedAlert } from "../../../components/notificationAlert/alert";

const CounterCard = (props) => {
  return (
    <div className="media">
        <span className="me-3">
          {props.icon}
        </span>
        <div className="media-body text-white">
          <small className="mb-1 text-white fw-bold">{props.title}</small>
          <h3 className="text-white">{props.number}</h3>
        </div>
    </div>
  )
};

const Beranda = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const [counterDokumen, setCounterDokumen] = useState({});

  useEffect(() => {
    fetchCounter();

    const storedUserDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (storedUserDetails) {
      setUserDetails(storedUserDetails);
    }
  }, []);

  const fetchCounter = () => {
    if (isFetching) return;

    setIsFetching(true);

    getCounterDocument()
      .then((response) => {
        var status = response.data.status;

        if (status) {
          setCounterDokumen(response.data.data);
        } else {
          failedAlert("Gagal Memuat Informasi Jumlah Dokumen", response.data.message);
        }
      })
      .catch((error) => {
        failedAlert("Gagal Memuat Informasi Jumlah DokumenDokumen", error.response.data.message);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  return (
    <>
      <Row>
        <Col xl={'12'} xxl={'12'} lg={'12'} sm={'12'}>
          <div className="text-center">
            <div className="mb-3">
              <img src={logo} alt="logo" height="80" />
            </div>
            <h4 className='fw-normal mb-0'>Selamat Datang</h4>
            <h3>{userDetails.nama}</h3>
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col xl={'4'} xxl={'4'} sm={'12'}>
        <div className={`widget-stat card bg-info`}>
          <div className="card-body p-4">
            {isFetching ? (
              <div className="mt-2 text-center d-flex align-items-center justify-content-center text-white">
                <Spinner animation="border" role="status" className="me-2">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>Memuat...</span>
              </div>
            ) : (
              <CounterCard title='TOTAL DOKUMEN VERIFIKASI' number={counterDokumen.total_verifikasi} color='info' icon={<i className="las la-clipboard-check" />}/>
            )}
            </div>
          </div>
        </Col>		
        {/* <Col xl={'4'} xxl={'4'} sm={'12'}>
          <div className={`widget-stat card bg-warning`}>
            <div className="card-body p-4">
            {isFetching ? (
              <div className="mt-2 text-center d-flex align-items-center justify-content-center text-white">
                <Spinner animation="border" role="status" className="me-2">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>Memuat...</span>
              </div>
            ) : (
              <CounterCard title='TOTAL DOKUMEN REVISI' number={counterDokumen.total_revisi} color='warning' icon={<i className="la la-edit" />}/>
            )}
            </div>
          </div>
        </Col>		 */}
        <Col xl={'4'} xxl={'4'} sm={'12'}>
          <div className={`widget-stat card bg-danger`}>
            <div className="card-body p-4">
            {isFetching ? (
              <div className="mt-2 text-center d-flex align-items-center justify-content-center text-white">
                <Spinner animation="border" role="status" className="me-2">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>Memuat...</span>
              </div>
            ) : (
              <CounterCard title='TOTAL DOKUMEN BATAL' number={counterDokumen.total_batal} color='danger' icon={<i className="la la-ban" />}/>
            )}
            </div>
          </div>
        </Col>		
        <Col xl={'4'} xxl={'4'} sm={'12'}>
          <div className={`widget-stat card bg-success`}>
            <div className="card-body p-4">
            {isFetching ? (
              <div className="mt-2 text-center d-flex align-items-center justify-content-center text-white">
                <Spinner animation="border" role="status" className="me-2">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>Memuat...</span>
              </div>
            ) : (
              <CounterCard title='TOTAL DOKUMEN DISETUJUI TTE/MANDIRI' number={counterDokumen.total_sign} color='success' icon={<i className="la la-pen-nib" />}/>
            )}
            </div>
          </div>
        </Col>		
      </Row>
    </>
  );
}

export default Beranda;
